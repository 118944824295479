// eslint-disable-next-line consistent-return
import { getInstance } from '@/auth/auth0-plugin'

// eslint-disable-next-line import/prefer-default-export, consistent-return
export const authenticationGuard = (to, from, next) => {
  const authService = getInstance()

  // eslint-disable-next-line consistent-return
  const guardAction = () => {
    if (authService.isAuthenticated) {
      return next()
    }

    authService.getTokenSilently()
      .then(() => {
        next()
      })
      .catch(() => {
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
      })
  }

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction()
  }

  // eslint-disable-next-line consistent-return
  authService.$watch('isLoading', isLoading => {
    if (isLoading === false) {
      return guardAction()
    }
  })
}
