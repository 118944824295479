import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import { authenticationGuard } from '@/router/guards/authGuard'
import { activitiesGuard } from '@/router/guards/activitiesGuard'
import { isLoggedInGuard } from '@/router/guards/isLoggedInGuard'
import store from '@/store'
import VueGtm from '@gtm-support/vue2-gtm'

// Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/launchpad',
      name: 'launchpad',
      component: () => import('@/views/Launchpad.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      redirect: {
        name: 'launchpad',
        query: {
          action: 'register',
        },
      },
    },
    {
      path: '/login',
      name: 'login',
      redirect: {
        name: 'launchpad',
        query: {
          action: 'login',
        },
      },
    },
    {
      path: '/auth/callback',
      redirect: () => 'home',
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: authenticationGuard,
    },
    {
      path: '/subscription-success',
      name: 'subscription-success',
      component: () => import('@/views/SubscriptionSuccess.vue'),
      beforeEnter: authenticationGuard,
    },
    {
      path: '/virtual-groups',
      name: 'virtual-groups',
      component: () => import('@/views/VirtualGroups.vue'),
      beforeEnter: authenticationGuard,
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/activities/check',
      name: 'activities-check',
      component: () => import('@/components/GenericLoading.vue'),
      beforeEnter: authenticationGuard,
    },
    {
      path: '/wizard/:wizardId',
      name: 'wizard',
      component: () => import('@/views/MWizard.vue'),
      meta: {
        layout: 'side-pane',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/assessment/:assessmentId',
      name: 'assessment',
      component: () => import('@/views/AssessmentView.vue'),
      meta: {
        layout: 'side-pane',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/care-pairing',
      name: 'care-pairing',
      component: () => import('@/views/CarePairingView.vue'),
      meta: {
        layout: 'side-pane',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/care-pairing-therapist',
      name: 'care-pairing-therapist',
      component: () => import('@/views/CarePairingViewTherapist.vue'),
      meta: {
        layout: 'side-pane',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/accept-policy/:policySlug',
      name: 'accept-policy',
      component: () => import('@/views/AcceptPolicy.vue'),
      meta: {
        layout: 'side-pane',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/SubscribeView.vue'),
      meta: {
        layout: 'side-pane',
      },
      beforeEnter: authenticationGuard,
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('@/views/Welcome2.vue'),
       meta: {
        layout: 'welcome',
      },
      beforeEnter: isLoggedInGuard,
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      redirect: () => 'welcome',
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  store.commit('app/updateIsLoading', true)
  next()
})
router.beforeEach(activitiesGuard)

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  store.commit('app/updateIsLoading', false)
})

Vue.use(VueRouter)

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  queryParams: {
    gtm_auth: process.env.VUE_APP_GTM_AUTH_ID,
    gtm_preview: process.env.VUE_APP_GTM_ENV,
    gtm_cookies_win: 'x',
  },
  vueRouter: router,
})

export default router
