import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(async config => {
  const value = await Vue.prototype.$auth.getTokenSilently()

  // eslint-disable-next-line no-param-reassign
  config.headers = {
    Authorization: `Bearer ${value}`,
  }

  return config
}, error => {
  Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
