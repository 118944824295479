import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    isLoading: false,
    activities: null,
    dashboard: null,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    activities: state => state.activities,
    dashboard: state => state.dashboard,
    isLoading: state => state.isLoading,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    updateActivities(state, val) {
      state.activities = val
    },
    updateDashboard(state, val) {
      state.dashboard = val
    },
    updateIsLoading(state, val) {
      state.isLoading = val
    },
    markActivityAsNonOptionalAtIndex(state, index) {
      state.activities[index].isOptional = false
    },
  },
  actions: {
    fetchActivities() {
      return axiosIns.get('/user/activities')
    },
    fetchDashboard() {
      axiosIns.get('/user/dashboard')
        .then(response => {
          store.commit('app/updateDashboard', response.data)
        })
    },
  },
  modules: {
  },
}
