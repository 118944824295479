import store from '@/store'

// eslint-disable-next-line import/prefer-default-export, consistent-return
export const activitiesGuard = async (to, from, next) => {
  if (to.name === 'subscription-success') return next()
  let activityFetchAttemptCount = 0
  let activities = store.getters['app/activities']

  const handleActivities = () => {
    if (activities != null && activities.length > 0) {
      const index = activities.findIndex(a => !a.isOptional)

      if (index >= 0) {
        const activity = activities[index]
        const activityId = activity.id

        if (activity.activityType !== undefined && activity.activityType === 'assessment') {
          if (to.name !== 'assessment' && to.params.assessmentId !== activity.activityMetaData) {
            return next({
              replace: true,
              name: 'assessment',
              params: {
                assessmentId: activity.activityMetaData,
                allowFinishLater: activity.isOptional,
              },
            })
          }
        } else if (activity.activityType !== undefined && activity.activityType === 'accept-policy') {
          if (to.name !== 'accept-policy' && to.params.policySlug !== activity.activityMetaData) {
            return next({
              replace: true,
              name: 'accept-policy',
              params: {
                policySlug: encodeURI(activity.activityMetaData),
              },
            })
          }
        } else if (activity.journeyId !== undefined && activity.renderConfig.groupRenderType === 'wizard') {
          if (to.name !== 'wizard' && to.params.wizardId !== +activityId) {
            return next({
              replace: true,
              name: 'wizard',
              params: {
                wizardId: activityId,
              },
            })
          }
        }
      }
    }

    return false
  }

  const fetchActivities = async () => {
    if (activityFetchAttemptCount === 5) {
      return false
    }

    try {
      activityFetchAttemptCount += 1
      const response = await store.dispatch('app/fetchActivities')
      return response.data
    } catch (error) {
      if (error.response != null && error.response.status === 404) {
        await new Promise(r => setTimeout(r, 3000))
        return fetchActivities()
      }

      return false
    }
  }

  if (to.name === 'subscription-success' || to.name === 'welcome') return next()

  if (activities === null || to.params.forceActivityRefresh) {
    activities = await fetchActivities()
    store.commit('app/updateActivities', activities)
  }

  if (activities != null && activities !== false) {
    const handlingResult = handleActivities()
    if (handlingResult === false) {
      next()
    } else {
      return handlingResult
    }
  } else {
    next()
  }
}
