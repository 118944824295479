/**
 *  External Modules
 */
import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'

/**
 *  Vue.js Instance Definition
 */
let instance
export const getInstance = () => instance

/**
 *  Vue.js Instance Initialization
 */
export const useAuth0 = ({ onRedirectCallback = () => window.history.replaceState({}, document.title, window.location.pathname), redirectUri = `${window.location.origin}/auth/callback`, ...pluginOptions }) => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        isLoading: true,
        isAuthenticated: false,
        user: null,
        error: null,
      }
    },
    async created() {
      await this.ensureClient()

      try {
        if (window.location.search.includes('code=')
            && window.location.search.includes('state=')) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          onRedirectCallback(appState)
        }
      } catch (error) {
        this.error = error
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.isLoading = false
      }
    },
    methods: {
      async ensureClient() {
        if (this.auth0Client !== null) {
          return
        }

        this.auth0Client = await createAuth0Client({
          ...pluginOptions,
          domain: pluginOptions.domain,
          client_id: pluginOptions.clientId,
          audience: pluginOptions.audience,
          redirect_uri: redirectUri,
        })
      },
      async handleRedirectCallback() {
        await this.ensureClient()

        this.isLoading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
        } catch (error) {
          this.error = error
        } finally {
          this.isLoading = false
        }
      },

      async loginWithRedirect(options) {
        await this.ensureClient()
        return this.auth0Client.loginWithRedirect(options)
      },

      async logout(options) {
        await this.ensureClient()
        let o = options
        const { location } = window
        const redirect = `${location.protocol}//${location.host}/launchpad`

        if (o === undefined || o == null) {
          o = {
            redirectTo: redirect,
          }
        } else if (o.redirectTo === undefined) {
          o.redirectTo = redirect
        }
        return this.auth0Client.logout(o)
      },

      async getTokenSilently(o) {
        await this.ensureClient()
        return this.auth0Client.getTokenSilently(o)
      },

      async refreshUser(force = false) {
        if (force || this.user == null) {
          await this.ensureClient()
          this.user = await this.auth0Client.getUser()
        }

        return this.user
      },
    },
  })

  return instance
}

/**
 *  Vue.js Plugin Definition
 */
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
