<template>
  <div class="overlay text-center">
    <b-spinner
      class="main-spinner"
      variant="primary"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'GenericLoading',
  components: {
    BSpinner,
  },
}
</script>

<style scoped>
.overlay {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-spinner {
  width: 3vw;
  height: 3vw;
}
</style>
