// eslint-disable-next-line consistent-return
import { getInstance } from '@/auth/auth0-plugin'

// eslint-disable-next-line import/prefer-default-export, consistent-return
export const isLoggedInGuard = (to, from, next) => {
  const authService = getInstance()

  // eslint-disable-next-line consistent-return
  const guardAction = () => {
    if (authService.isAuthenticated) {
      next({ name: 'home' })
    }

    authService.getTokenSilently()
      .then(() => {
        next({ name: 'home' })
      })
      .catch(() => {
        next()
      })
  }

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    console.debug(authService)
    return guardAction()
  }

  // eslint-disable-next-line consistent-return
  authService.$watch('isLoading', isLoading => {
    if (isLoading === false) {
      return guardAction()
    }
  })
}
